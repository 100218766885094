/* 

.log {
    text-align: center;
    
  }
  
  h2 {
    color:rgb(200, 133, 74) ;
    font-weight: 600;
    font-size: 30px;
  
  }
  #LoginFomrContainer{
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  
  }
  
   form{
    height: 400px;
    width:500px;
    display: inline-block;
    text-align: left;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 5px;
    background-color: rgba(215, 179, 148, 0.677);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .outlined-basic {
    display: block;
    margin-bottom: 4px;
    color: rgb(66, 58, 58);
  }
  
  input {
    width: 90%;
    padding: 10px;
    margin-bottom: 15px;
  }
  
  button {
    background-color:rgb(200, 133, 74);
    color: white;
    padding: 10px 25px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 auto;
    display: block;
      
  }
  
  button:hover {
    background-color: rgb(202, 171, 142) ;
  }
  
  */

/* Login.module.css */

#button {
  background-color: rgb(48, 143, 174);
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-size: 1rem;
}

#button:hover {
  background-color: rgb(48, 143, 174);
}

#LoginFomrContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92vh;
  background-image: url("https://img.freepik.com/free-vector/watercolour-light-blue-background_78370-3537.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.loginform {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  width: 90%;
  padding: 20px !important;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
 
}

.heading {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: rgb(48, 143, 174);
  text-align: center;
}


/* Media Queries */
@media  (max-width: 600px) {
  .loginform {
    width: 80%;
  }
}

@media  (max-width: 400px) {
  .loginform {
    width: 95%;
  }

  .heading {
    font-size: 1.2rem;
  }
}
