.navbarContainer {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
     width: 100% !important;
     height:7vh;
    background-color: rgb(48, 143, 174)!important;
   

    
  }
