.academic-card {
    /* transition: transform 0.3s; */
    box-shadow: 0 4px 8px rgba(252, 249, 249, 0.1);
    border-radius: 8px;
    border:2px solid black;
   /* background-color: rgb(200, 200, 209) !important; */
   background-image: url("https://img.freepik.com/free-photo/gray-texture_1253-153.jpg");
  }
  
  .academic-card:hover {
    /* transform: translateY(-5px); */
    box-shadow: 0 8px 16px rgba(245, 242, 242, 0.2);
  }

  