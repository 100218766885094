
  
  .orgStyle{
    padding:19px !important;
    text-align: center !important;
    background-color:#98afc7ab!important;
  }
  
  .branchStyle{
    padding:19px !important;
    text-align: center !important;
    background-color: #C4C3D0;
    text-decoration: underline;
  }
  .batchStyle{
    padding:19px !important;
    text-align: center !important;
    background-color: #F0F0F0;
    text-decoration: underline;
  }
  
  .appointmentStyle{
    height:7.5em !important;
      text-align: center !important; 
  }
  .appointmentStyle1{
    height:7.5em !important;
    text-align: center !important; 
  }
  .collegeStyle1{
    padding: 18.5px !important;
  }
  .appointmentTree{
    padding: 39.5px !important;
  
  }
  
  /* @media only screen and (min-width: 1536px) and (max-width:1540px){
    .stateStyle{
      padding:18.5px !important;
      text-align: center !important;
    }
    .districtStyle{
      padding:18.5px !important;
      text-align: center !important;
    }
    .collegeStyle{
      padding:16.40px !important;
      text-align: center !important;
    }
    .collegeStyle1{
      padding: 18.4px !important;
    }
  } */

  #container {
    margin-top: 30px !important;
    /* display:flex; */
  }
  
  #toggle {
   background-color:rgb(159, 205, 221)!important;
   position: absolute !important;
   /* float:right; */
   right: 0% !important;
   border-radius: 5px !important;
   
  }

  #toggle1 {
    background-color:rgb(159, 205, 221)!important;
    /* float: right; */
    position: absolute !important;
    right:0%!important;
    border-radius: 5px !important;
    
   }
  
  .tables-container {
    display: flex!important;
    justify-content: center!important
  
  }
  
  .table {
    border-collapse: collapse !important;
    width: 300px !important;
    margin: 0 !important;
   
  }

  
  
  